.image {
  width: 40%;
  max-width: 580px;
  height: auto;
}

.image object,
.image img {
  width: 100%;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 14px 60px 14px 30px;
  text-decoration: none;
  background: var(--cards);
  box-shadow: 0px 4px 20px var(--outer-shadow-colour_1);
}

/* TODO: convert to ChevronIcon and CheckIcon */
.card::before,
.card::after {
  content: '';
  display: block;
  position: absolute;
}

.card::before {
  width: 20px;
  height: 20px;
  border: 4px solid var(--primary);
  right: 30px;
  top: 50%;
  margin-top: -10px;
  transform: rotate(45deg);
  border-bottom: none;
  border-left: none;
}

.card.completed {
  pointer-events: none;
}

.card.completed::before {
  background: var(--primary);
  border-radius: 50%;
  transform: none;
  border: none;
  height: 30px;
  width: 30px;
  margin-top: -15px;
  right: 20px;
}

.card.card.completed::after {
  width: 16px;
  height: 10px;
  border: solid 3px var(--contrast-text);
  border-top: none;
  border-right: none;
  right: 25px;
  top: 50%;
  margin-top: -5px;
  transform: rotate(-45deg) translate(0px, -3px);
}

.cards {
  display: grid;
  grid-auto-rows: 1fr;
  gap: 30px;
  margin: 0;
  padding: 30px 0;
}

.content {
  padding-left: 80px;
  padding-right: 50px;
  flex: 1;
}

.page {
  width: 100%;
  min-height: calc(100vh - 93px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1250px;
  margin: auto;
}

/* tablet */
@media screen and (max-width: 930px) {
  .image {
    display: none;
  }

  .content {
    padding: 0 30px;
    padding-top: 45px;
  }

  .page {
    display: block;
  }
}
