.skillsReportPageContainer {
  width: 100%;
  padding-bottom: 120px;
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.skillsReportCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 10px 30px;
  backdrop-filter: blur(15px);
  background: var(--cards_1);
}

.skillsReportContent {
  max-width: calc(100% - 340px);
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.skillsReportContentTabSlide {
  position: relative;
  z-index: 2;
  padding: 20px;
  padding-top: 0px;
  pointer-events: all;
}

.skillsReportContentLevel {
  text-align: left;
}

.skillsReportContentSunburst {
  width: 350px;
}

.skillsReportContentList {
  padding-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.skillsReportContentAnchor {
  display: block;
  text-align: right;
  width: 100%;
}

.skillsReportContentHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border-bottom: solid 1px var(--borders_1);
  padding-bottom: 10px;
  gap: 5px;
}

.skillsReportContentHeading {
  line-height: 2rem;
}

.skillsReportContentScore {
  display: flex;
  align-items: center;
  gap: 20px;
}

.skillsReportRoleBasedTabSlide {
  height: 100%;
  min-height: 350px;
  padding: 30px 0px;
}

.skillsReportEmptyStateCard,
.skillsReportRoleBasedCard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.sideNavCollapsed {
  width: calc(100% - 90px);
}

.skillItemList {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 8px;
}

.listItem {
  display: flex;
  align-items: center;
  gap: 15px;
}

.listItemColor {
  width: 10px;
  height: 10px;
}

.listItemName {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1165px) {
  .isFirst {
    padding-left: 0;
  }

  .isLast {
    padding-right: 0;
  }

  .skillsReportContent {
    max-width: calc(100% - 280px);
  }

  .skillsReportContentHeader {
    gap: 5px;
    flex-direction: column;
  }

  .skillsReportContentHeading {
    font-size: 1.3rem;
  }

  .skillsReportContentLevel {
    width: 100%;
  }

  .skillsReportContentSunburst {
    width: 350px;
  }

  .skillsReportCard {
    padding: 0px;
    border: none;
    box-shadow: none;
  }

  .skillsReportContent {
    max-width: 100%;
    padding: 20px;
    background: var(--cards);
    box-shadow: 0px 4px 16px var(--outer-shadow-colour);
    border-radius: 20px;
  }

  .skillsReportContentList {
    display: none !important;
  }

  .skillsReportContentAnchor {
    padding-top: 10px;

    a {
      text-align: center;
      line-height: 0.625rem;
    }
  }

  .skillsReportContentHeader {
    padding-bottom: 10px;
    gap: 10px;
    align-items: flex-start;
  }

  .skillsReportContentTabSlide {
    padding: 10px 0px;
  }

  .skillsReportContentLevel {
    width: 200px;
  }

  .sideNavCollapsed {
    width: 100%;
  }
}

@media screen and (max-width: 580px) {
  .skillsReportContentLevel {
    width: 90px;
  }
}
@media screen and (max-width: 390px) {
  .skillsReportContentHeader {
    gap: 15px;
  }
}

/* refactored */
.td {
  padding: 5px;
  border-top: solid 1px var(--borders);
  border-bottom: solid 1px var(--borders);
}

.td:first-child {
  border-left: solid 1px var(--borders);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 10px;
}

.td:last-child {
  border-right: solid 1px var(--borders);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table {
  width: 100%;
  font-size: 0.75rem;
  border-collapse: separate;
  border-spacing: 0 5px;
  table-layout: fixed;
}
