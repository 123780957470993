.card {
  position: relative;
  margin-top: 20px;
  min-height: 65px;
  padding: 15px 25px 15px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cardEnd {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

/* mobile */
@media (max-width: 580px) {
  .cardEnd {
    flex-direction: column;
  }

  .card {
    flex-direction: column;
    align-items: flex-start;
  }
}
