.button {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 42px;
  padding: 2px 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 50px;
  background-color: var(--cards);
  border: 1.5px solid var(--borders);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  font-size: 0.78rem;
  cursor: pointer;
  color: var(--text);
}

.option {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  font-size: 0.78rem;
}

.list {
  position: absolute;
  background-color: var(--cards);
  color: var(--text);
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--borders);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  margin-bottom: 10px;
  right: 0px;
  overflow-y: auto;
  max-height: 50vh;
  min-width: 200px;
}

.container {
  display: inline-block;
  z-index: 9999;
}

/* mobile */
@media (max-width: 580px) {
  .list {
    max-height: 70vh;
  }
}
