.toggle {
  display: flex;
  align-items: center;
  font-size: 0.78rem;
  min-height: 50px;
}

.card {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.card:hover {
  border-color: var(--text);
}

.toggleCard {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin-bottom: 25px;
}

/* tablet */
@media screen and (max-width: 930px) {
}

/* mobile */
@media (max-width: 580px) {
}
