/* OTHER - START */
.sideNavPanelToggleButton {
  position: absolute;
  top: 95px;
  right: 0;
  z-index: 20;
  transform: translateX(50%);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 35px;
  height: 35px;
  border: solid 1px var(--secondary);
  border-radius: 50%;

  background: var(--cards);
  overflow: hidden;
  cursor: pointer;

  /* animation */
  will-change: top, transform;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;

  svg {
    transform-origin: center center;
    transform: rotate(180deg);

    /* animation */
    will-change: transform, transform-origin;
    transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
  }
}

.sideNavPanelToggleButton:hover {
  transform: scale(0.9) translateX(50%);
}

.sideNavPanelToggleButton.sideNavCollapsed {
  svg {
    transform: rotate(0deg);
  }
}

.sideNavPanelVersion {
  padding: 0px 0px 20px;

  color: var(--text_1);
  font-size: 0.67rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  overflow: hidden;

  /* animation */
  opacity: 1;
  will-change: opacity;
  transition: opacity cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
  animation: fadePoweredByLogoAndVersion 3s forwards;
}

.sideNavPanelVersion.sideNavCollapsed {
  display: none;
}

.sideNavPanelContainer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;

  display: grid;
  grid-template-rows: auto 1fr auto;

  width: 245px;
  min-height: 100%;
  max-height: 100%;
  border-right: solid 1px var(--borders_1);

  background: var(--cards);
  box-shadow: 0px 0px 20px var(--outer-shadow-colour_1);
  transform: translateX(-300px);

  /* animation */
  will-change: width, transform;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
}

.sideNavPanelContainer.visible {
  transform: translateX(0%);
}

.sideNavPanelContainer.sideNavCollapsed {
  width: 90px;
  grid-template-rows: auto 1fr 0;
}

.sideNavPanelPoweredByLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 5px 0 5px;
  opacity: 1;

  will-change: opacity;
  transition: opacity cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
  animation: fadePoweredByLogoAndVersion 3s forwards;
}

.sideNavPanelPoweredByLogo.sideNavCollapsed {
  display: none;
}

/* OTHER - END */

/* LOGO - START */
.sideNavPanelLogo {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  height: 50px;
  width: auto;

  /* animation */
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
}

.sideNavPanelLogo.text {
  object-fit: contain;

  /* animation */
  opacity: 1;
  will-change: opacity;
}

.sideNavPanelLogo.text.sideNavCollapsed {
  opacity: 0;
}

.sideNavPanelLogo.icon {
  /* animation */
  opacity: 0;
  will-change: left, transform, opacity;
}

.sideNavPanelLogo.icon.sideNavCollapsed {
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  opacity: 1;
}

.sideNavPanelLogoContainer {
  position: relative;

  display: block;

  width: 100%;
  height: 100px;

  overflow: hidden;
}
/* LOGO - END */

/* ALL COMPANIES - START */
.sideNavPanelAllCompaniesExpanded {
  position: absolute;
  left: 10px;
  font-size: 0.89rem;

  /* animation */
  opacity: 1;
  will-change: opacity, transform, nowrap;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
}

.sideNavPanelAllCompaniesExpanded:hover {
  border-bottom-color: var(--primary);
}

.sideNavPanelAllCompaniesExpanded.sideNavCollapsed {
  opacity: 0;
  transform: translateX(-200%);
  white-space: nowrap;
}

.sideNavPanelAllCompaniesCollapsed {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.5rem;

  /* animation */
  opacity: 0;
  will-change: opacity;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
}

.sideNavPanelAllCompaniesCollapsed:hover {
  border-bottom-color: var(--secondary);
}

.sideNavPanelAllCompaniesCollapsed.sideNavCollapsed {
  opacity: 1;
}
/* ALL COMPANIES - END */

/* ROUTES - START */
.sideNavPanelLearnerRouteListContainer {
  margin-top: 20px;
}

.sideNavPanelCompany {
  margin: 10px 0px 0px 10px;

  /* animation */
  will-change: transform;
  transition: transform cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
}

.sideNavPanelCompany.sideNavCollapsed {
  transform: translateX(5%);
}

.sideNavPanelRoutesContainer {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
/* ROUTES - END */
@keyframes fadePoweredByLogoAndVersion {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

