.loader {
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--borders_2);
  overflow: hidden;
  z-index: 45;
}

.loader::after {
  content: '';
  position: absolute;
  top: 0;
  left: -150%;
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg, transparent 0%, transparent 35%, var(--text_2) 50%, transparent 75%, transparent 100%);
  opacity: 0.2;
  animation: SkeletonLoaderShine linear 1500ms infinite;
}

@keyframes SkeletonLoaderShine {
  from {
    left: -150%;
  }

  to {
    left: 150%;
  }
}
