/* default state */
.tertiaryButton {
  height: 44px;
  min-width: 44px;
  background-color: transparent;
  color: var(--link-text);
  border: none;
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.78rem;
  font-family: var(--body);

  /* animation */
  will-change: color;
  transition: color 300ms;
}

/* hover state */
.tertiaryButton:hover {
  color: var(--link-text_1);
}

/* focus state (using 'focus-visible' rather than 'focus' because we only want buttons to have outlines when keyboard navigation was used) */
.tertiaryButton:focus-visible {
  outline: 1px solid var(--text);
}

/* disabled state (using 'aria-disabled' rather than 'disabled' so that buttons aren't hidden from keyboard navigation) */
.tertiaryButton[aria-disabled="true"] {
  cursor: not-allowed;
  color: var(--default);
}
