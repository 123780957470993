.oneTwoGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 30px;
  align-items: start;
}

.twoOneGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 30px;
  align-items: start;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  padding-bottom: 30px;
}

/* mobile */
@media (max-width: 580px) {
  .oneTwoGrid,
  .twoOneGrid {
    grid-template-columns: 1fr;
  }
}
