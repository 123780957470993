.skillsWrapper {
  gap: 30px;
  align-items: start;
}

.skillsFilters {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto minmax(0px, 1fr);
  align-items: center;
  gap: 30px;
}

@media (max-width: 580px) {
  .skillsWrapper {
    gap: 15px;
  }

  .skillsFilters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;
  }
}

.skillsClearFiltersButton {
  margin-left: auto;
  border-top: 2px solid transparent;
  border-right: none;
  border-bottom: 2px solid transparent;
  border-left: none;
  height: fit-content;
  width: fit-content;

  display: inline-flex;
  align-items: center;
  gap: 5px;

  background: none;

  font-weight: 500;
  font-family: var(--body);
  line-height: 1.22rem;
  text-transform: uppercase;
  color: var(--secondary);
  white-space: nowrap;

  cursor: pointer;
}

.skillsClearFiltersButton:hover {
  border-bottom-color: var(--primary);
}

.skillsSyncToggleActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

