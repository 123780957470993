/* toast-hub */
.icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  margin: 0px;
}

.icon svg {
  width: 40px;
  height: 40px;
}

.paragraph a {
  text-decoration: none;
  color: var(--secondary);
}

.paragraph a:hover {
  text-decoration: underline;
}

.content {
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.content.hasIcon {
  padding: 20px 40px 20px 60px;
}

/* toast */
.dialog {
  position: fixed;
  bottom: 10px;
  left: 50%;
  z-index: 2;
  margin: 0;
  min-height: 40px;
  width: 500px;
  border: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--cards);
  box-shadow: 0px 4px 20px var(--outer-shadow-colour);

  /* animation */
  opacity: 1;
  transform: translateY(0%) translateX(-50%);
  animation: FadeInToast 500ms, SlideInToast 500ms;
}

.dialog.Info {
  border-bottom: 2px solid var(--secondary);
}

.dialog.Error {
  border-bottom: 2px solid var(--r);
}

.dialog.Warn {
  border-bottom: 2px solid var(--a);
}

.dialog.Success {
  border-bottom: 2px solid var(--g);
}

@keyframes FadeInToast {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes SlideInToast {
  from {
    transform: translateY(100%) translateX(-50%);
  }
  to {
    transform: translateY(0%) translateX(-50%);
  }
}
