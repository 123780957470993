.legend {
  margin-left: -20px;
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--borders);
  padding: 5px 10px;
  height: 30px;
  border-radius: 15px;
  background-color: var(--background);
}

