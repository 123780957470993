.layout {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.detailsHeader {
  display: flex;
  flex-direction: column;
  gap: 10px;
}