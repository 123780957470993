/* TODO: refactor to be in line with other inputs */

/* input */
.label {
  appearance: none;
  margin: 0;
  padding: 0;
  display: block;
  font-size: 12px;
  color: var(--accent-3);
  font-weight: 500;
  font-family: var(--heading);
}

.output {
  appearance: none;
  margin: 0;
  padding: 0;
  display: block;
  font-size: 14px;
  color: var(--text);
  white-space: nowrap;
}

.range {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  pointer-events: none;
  flex: 1;
  white-space: nowrap;
}

.trigger {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background: var(--background);
  border: 1px solid var(--borders);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 10px 0 18px;
  cursor: pointer;
  gap: 15px;
}

/* dialog */
.customOutput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-top: 5px;
}

.customContent {
  display: block;
  flex: 1 0 auto;
}

.radioButtonContent {
  display: flex;
  flex-direction: column;
}

.radioButton {
  display: block;
  width: 40px;
  height: 40px;
  flex: auto auto auto;
  position: relative;
}

.radioButton::before,
.radioButton::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.radioButton::before {
  width: 14px;
  height: 14px;
  border: solid 1px var(--secondary);
  border-radius: 50%;
  margin: -7px;
}

.radioButton::after {
  width: 10px;
  height: 10px;
  margin: -5px;
  background: var(--secondary);
  border-radius: 50%;
  transform: scale(0.75);
  opacity: 0;
  will-change: opacity, transform;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 150ms;
}

.radioButton.checked::after {
  opacity: 1;
  transform: scale(1);
}

.listItem {
  display: flex;
  cursor: pointer;
  padding: 0px 20px;
  margin: 0px 0px;
  border-top: solid 1px transparent;
  border-bottom: solid 1px transparent;
  background: transparent;
  will-change: padding, margin, background, border-color;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 250ms;
}

.listItem.custom {
  align-items: flex-start;
  border-bottom: solid 1px var(--borders);
  padding-bottom: 15px;
}

.listItem.checked {
  padding: 10px 20px;
  margin: 5px 0;
  background: var(--background);
  border-top-color: var(--borders);
  border-bottom-color: var(--borders);
}

.listItem.custom.checked {
  padding-bottom: 15px;
  margin-bottom: 0px;
}

.list {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;
  padding: 0;
  gap: 5px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  text-align: right;
  gap: 15px;
  padding: 5px 30px 5px 30px;
}

.dialogContent {
  display: block;
  width: 90%;
  max-width: 330px;
  margin: auto;
  background: var(--cards);
  border: 1px solid var(--borders);
  border-radius: 12px;
  padding: 35px 0 20px 0;
  z-index: 2;
  user-select: none;
  position: relative;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

.background {
  background: var(--background_1);
  backdrop-filter: blur(3px);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  cursor: pointer;
}

.dialog {
  appearance: none;
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1006;
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
}

.dialog[open] {
  opacity: 1;
  pointer-events: all;
}
