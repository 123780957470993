.widgetContainer {
  display: flex;
  gap: 60px;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

