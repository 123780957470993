.lessonCardHeader {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  height: 130px;
  width: 100%;
  display: flex;
  background-color: var(--primary);
}

.lessonCardHeader img,
.lessonCardHeader svg {
  height: auto;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

@supports (aspect-ratio: 16 / 9) {
  .lessonCardHeader {
    padding-top: 0;
    aspect-ratio: 16 / 9;
  }
}

.lessonCardBody {
  height: 100%;
  padding: 15px;

  display: flex;
  flex-direction: column;
}

.lessonCardBody :global .caption1 {
  display: flex;
  align-items: center;

  margin-top: auto;
  padding-top: 15px;

  color: var(--apple);
}

.lessonCardBody :global .body2 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  line-clamp: 5;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: left;
}

.lessonCardFooter {
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 50px;
  padding: 0 25px;
}

.lessonCardFooter svg {
  margin-top: 1px;
}

.lessonCardFooter.borderTop {
  border-top: 1px solid var(--borders);
}

.lessonCard {
  position: relative;
  display: block;
  height: 100%;
  padding-top: 130px;
  border: solid 1px var(--borders);
  border-radius: 8px;
  background: var(--cards);
  box-shadow: 0px 4px 8px var(--outer-shadow-colour);
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
}

.lessonCard :global .caption1 {
  text-transform: uppercase;
  font-weight: bold;
}

.lessonCard.disabled {
  cursor: not-allowed;
}

.lessonCard.disabled img {
  filter: grayscale(100%);
}

.lessonCard.disabled svg path {
  fill: var(--text_1);
}

.lessonCard.disabled :global .subtitle,
.lessonCard.disabled :global .caption2 {
  color: var(--default);
}

.lessonCard.disabled .caption1 {
  color: var(--text_1);
}

.lessonCard.footer {
  padding-bottom: 50px;
}
