.link {
  color: var(--link-text);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.column {
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.column.name {
  text-overflow: ellipsis;
  overflow: hidden;
}

.column.label {
  font-weight: 700;
  font-family: var(--heading);
}

.body {
  display: grid;
  grid-template-columns: minmax(0px, 1fr) 145px;
  gap: 15px;
}

.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* mobile */
@media screen and (max-width: 580px) {
  .column {
    align-items: start;
  }

  .header {
    flex-direction: column;
  }
}
