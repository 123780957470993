.profileEmailBlockDeleteButton {
  position: absolute;
  top: calc(50% - 50px);
  right: 15px;
  transform: translateY(calc(-50% + 50px));
}

.profileEmailBlockIndicator {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 0px;
  line-height: 1.11rem;
  font-size: 0.78rem;
}

.profileEmailBlockResendVerificationButton {
  background: transparent;
  border: none;
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
}

.profileEmailBlock {
  padding: 15px 0px;
}

.profileEmailBlock:not(:last-child) {
  border-bottom: solid 1px var(--borders);
}
