.form {
  display: flex;
  flex-direction: column;
  max-width: 850px;
  margin: auto;
  padding-top: 60px;
}

@media screen and (max-width: 580px) {
  .form {
    padding-top: 30px;
  }
}
