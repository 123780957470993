.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.iconContainer {
  width: 100%;
  max-width: 500px; 
  margin: 0 auto;
}

.icon {
  width: 100%;
  height: auto;
  max-width: 100%; 
}

@media (max-width: 768px) {
  .iconContainer {
    max-width: 300px; 
  }
}

@media (max-width: 480px) {
  .iconContainer {
    max-width: 200px; 
  }
}