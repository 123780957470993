.info {
  margin-bottom: 30px;
  border-top: solid 1px var(--borders);
  border-bottom: solid 1px var(--borders);
  padding: 15px;
}

.avatar {
  width: 50px;
  height: 50px;
  border: solid 2px var(--accent-2);
  border-radius: 50%;
  overflow: hidden;
}
