.button {
  display: flex;
  align-items: center;
  line-height: 1.22rem;
  font-size: 0.89rem;
  font-weight: 500;
  font-family: var(--body);
  text-decoration: none;
  text-transform: uppercase;
  color: var(--link-text);
  appearance: none;
  background: none;
  border: none;
  will-change: opacity, border-color;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;
  border-top: solid 2px transparent;
  border-bottom: solid 2px transparent;
  cursor: pointer;
}

.button:hover {
  border-bottom-color: var(--primary);
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 30px;
}
